import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import BlogHomeOne from './BlogHomeOne';
import FeaturesHomeOne from './FeaturesHomeOne';
import FooterHomeOne from './FooterHomeOne';
import HeroHomeOne from './HeroHomeOne';
import HomeOneHeader from './HomeOneHeader';
import ServicesHomeOne from './ServicesHomeOne';
import TestimonialHomeOne from './TestimonialHomeOne';

function HomeOne() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HomeOneHeader action={drawerAction.toggle} />
            <HeroHomeOne />
            <ServicesHomeOne />
            <FeaturesHomeOne />
            {/* <TrafficHomeOne /> */}
            <TestimonialHomeOne />
            {/* <TeamHomeOne /> */}
            {/* <PricingHomeOne /> */}
            {/* <FaqHomeOne /> */}
            <BlogHomeOne />
            {/* <ProjectHomeOne /> */}
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default HomeOne;
