import React from 'react';

function FooterHomeOne({ className }) {
    return (
        <>
            {/* <section className={`appie-footer-area ${className || ''}`}> */}
            <div className={`appie-footer-area container-fluid ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-copyright d-flex align-items-center justify-content-between pt-35">
                                <div className="apps-download-btn">
                                    <ul>
                                        <li>
                                            <a href="https://tjysc.cc/v20/public/file/app-release.apk">
                                                <i className="fab fa-google-play" /> 安卓端下载
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <span>
                                        备案号:
                                        <a
                                            href="https://beian.miit.gov.cn/#/Integrated/index"
                                            className="beian"
                                        >
                                            粤ICP备2023158713号-2
                                        </a>
                                    </span>
                                </div>
                                <div className="copyright-text">
                                    <p>Copyright © 彤洁雅</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* </section> */}
        </>
    );
}

export default FooterHomeOne;
